import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from '../../constants/AuthConstant';
// import FirebaseService from 'services/FirebaseService';
import AuthService from '../../services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	group: '',
	subgroup: '',
	admin: false,
	loc: '',
	user: '',
	showroom:'',
	isAuthenticated: false,
	userid: '',
	jobid: '',
	isClient: false,
	profilepic: '',
	access: ''
}

export const signIn = createAsyncThunk('login',async (data, { rejectWithValue  }) => {

	const { username, password } = data
	try {
		const response = await AuthService.login({username, password})

		if (response.RESULT === 'Authenticated') {
			const token = {"TOKEN" : response.Token , "GROUP" : response.Group  , "SubGroup": response.SubGroup, "Admin": response.hasAdmin, "LOCATION" : response.LocationId , "User" : response.User , "Showroom": response.Showroom ,"Userid" : response.Id, "ProfilePic": response.ProfilePic,"Access": response.Access }

			localStorage.setItem(AUTH_TOKEN, token.TOKEN);

			return token;
		}

	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const clientSignIn = createAsyncThunk('clientlogin',async (data, { rejectWithValue  }) => {

	const { username, password } = data
	try {
		const response = await AuthService.Clientlogin({username, password})

		if (response.RESULT === 'Authenticated') {
			const token = {"TOKEN" : response.Token , "GROUP" : "Client"  , "SubGroup": "", "Admin": false, "LOCATION" : response.LocationId , "User" : response.User , "Showroom": response.Showroom ,"Userid" : response.Id }

			localStorage.setItem(AUTH_TOKEN, token.TOKEN);

			return token;
		}

	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({email, password})
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    // const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
    // return response.data
	return
})



export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {

		onGroupChange: (state, action) => {
			state.group = action.payload
		},
		onLocChange: (state, action) => {
			state.loc = action.payload
		},
		onChangeJob: (state, action) => {
			state.jobid = action.payload
		},
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				// console.log(action)
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.TOKEN
				state.group = action.payload.GROUP
				state.subgroup = action.payload.SubGroup
				state.admin = action.payload.Admin
				state.loc = action.payload.LOCATION
				state.user = action.payload.User
				state.showroom = action.payload.Showroom
				state.userid = action.payload.Userid
				state.isAuthenticated = true
				state.isClient = false
				state.profilepic = action.payload.ProfilePic
				state.jobid = ""
				state.access = action.payload.Access
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(clientSignIn.pending, (state) => {
				state.loading = true
			})
			.addCase(clientSignIn.fulfilled, (state, action) => {
				// console.log(action)
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.TOKEN
				state.group = "Client"
				state.subgroup = ""
				state.admin = false
				state.loc = action.payload.LOCATION
				state.user = action.payload.User
				state.showroom = action.payload.Showroom
				state.userid = action.payload.Userid
				state.isAuthenticated = true
				state.isClient = true
				state.jobid = ""
				// state.profilepic = ""
			})
			.addCase(clientSignIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
				state.profilepic = ""
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
				
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	onGroupChange,
	onLocChange,
	onChangeJob
} = authSlice.actions

export default authSlice.reducer