import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "configs/AppConfig";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import store from "store";
import { notification } from "antd";
import { signOutSuccess } from "store/slices/authSlice";
// // use axios fetchbasequery\
// let jwtToken = null;
// jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

const unauthorizedCode = [400, 401, 403]


const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
      headers.set("Authorization", `JWT ${jwtToken}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  },

});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints(build) {
    return {
      // get all record
      getAppliance: build.query({
        query: () => ({ url: "/appliance", method: "get" }),
        providesTags: ["Appliance"],
      },),

      // get single record
      getApplianceById: build.query({
        query: (id) => ({ url: `/appliance/list/${id}`, method: "get" }),
        providesTags: ["Appliance"],
      }),
      // get appliance type
      getApplianceType: build.query({
        query: () => ({ url: "/appliance/type", method: "get" }),
        // providesTags: ["Appliance"],
      }),
      // get appliance brand
      getApplianceBrand: build.query({
        query: () => ({ url: "/appliance/brand", method: "get" }),
        // providesTags: ["Appliance"],
      }),
      // add appliance type
      addApplianceType: build.mutation({
        query: (data) => ({
          url: "/appliance/type",
          method: "post",
          body: data,
        }),
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            const { data: createdType } = await queryFulfilled;
              console.log(createdType.DATA[0])
            
            dispatch(
              apiSlice.util.updateQueryData(
                "getApplianceType",
                undefined,
                (draft) => {
                  draft.DATA.push(createdType.DATA[0]);
                }
              )
            );
          } catch (error) {
            console.log(error);
          }
        },
      }),

      // add appliance brand
      addApplianceBrand: build.mutation({
        query: (data) => ({
          url: "/appliance/brand",
          method: "post",
          body: data,
        }),
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            const { data: createdBrand } = await queryFulfilled;
            dispatch(
              apiSlice.util.updateQueryData(
                "getApplianceBrand",
                undefined,
                (draft) => {
                  draft.DATA.push(createdBrand.DATA[0]);
                }
              )
            );
          } catch (error) {
            console.log(error);
          }
        },
      }),

      // add appliance
      addAppliance: build.mutation({
        query: (data) => ({
          url: "/appliance",
          method: "post",
          body: data,
        }),
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            const { data: createAppliance } = await queryFulfilled;
            console.log(createAppliance.DATA)
            dispatch(
              apiSlice.util.updateQueryData(
                "getAppliance",
                undefined,
                (draft) => {
                  draft.DATA.push(createAppliance.DATA);
                }
              )
            );
          } catch (error) {
            console.log(error);
          }
        },
      }),

      // delete appliance
      deleteAppliance: build.mutation({
        query: (id) => ({
          url: `/appliance/delete/${id}`,
          method: "get",
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data: deletedAppliance } = await queryFulfilled;
            // console.log(deletedAppliance)
            dispatch(
              apiSlice.util.updateQueryData(
                "getAppliance",
                undefined,
                (draft) => {
                  draft.DATA = draft.DATA.filter(
                    (item) => item.id !== parseInt(deletedAppliance.ID)
                  );
                }
              )
            );
          } catch (error) {
            console.log(error);
          }
        },
      }),

      // // get Job update
      // getJobUpdate: build.query({
      //   query: () => ({ url: "/job/update", method: "get" }),
      //   providesTags: ["Jobs"],
      // }),

      // // get Job Status
      // getJobStatus: build.query({
      //   query: () => ({ url: "/job/status", method: "get" , 
      //   responseHandler: (response) => {
      //       //  check for unauthorized response  to logout
      //     if (unauthorizedCode.includes(response.status)) {
      //           localStorage.removeItem(AUTH_TOKEN)
      //           store.dispatch(signOutSuccess())
              
      //       }
      //       return response;
      //     }
          
      //   }),
      //   providesTags: ["Jobs"],
        
      //   })
        
      // get Job Status

      


    };
  },
});


// setupListeners(apiSlice.middleware, (getJobStatus, dispatch, action) => {
//   console.log(getJobStatus)
// });


  

export const {
  useGetApplianceQuery,
  useGetApplianceByIdQuery,
  useGetApplianceBrandQuery,
  useGetApplianceTypeQuery,
  useAddApplianceTypeMutation,
  useAddApplianceBrandMutation,
  useAddApplianceMutation,
  useDeleteApplianceMutation
} = apiSlice;
export default apiSlice.reducer;

