import rootReducer from './rootReducer';
import { configureStore , combineReducers  } from '@reduxjs/toolkit';


//CREATE PERISISTED STORE
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {apiSlice} from './rtk/apiSlice';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// const persistConfig = {
//

//CREATE PERSIStconfig
const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'theme' , 'api'],
	// stateReconciler: autoMergeLevel2,
};
//CHANGE CODE TO REDUX PERSIST
const persistedReducer = persistReducer(persistConfig, rootReducer());
// Combine your reducers, including the RTK Query reducer

// const combinedReducer = combineReducers({
// 	persistedReducer: persistReducer(persistConfig, rootReducer),
// 	[apiSlice.reducerPath]: apiSlice.reducer,
//   });

const middlewares = [];

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(apiSlice.middleware),
	devTools: process.env.NODE_ENV === 'development',
})

// const store = createStore(persistedReducer);
export const persistor = persistStore(store);

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
}


export default store;