import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import { AUTHENTICATED_ENTRY, CLIENT_ENTRY } from "../configs/AppConfig";
import {
  protectedRoutes,
  publicRoutes,
  clientRoutes,
} from "../configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { useSelector } from "react-redux";
// import ShowroomRoute from './ShowroomRoute';
import AppRoute from "./AppRoute";

const Routes = () => {
  const isClient = useSelector((state) => state.auth.isClient);
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={isClient ? CLIENT_ENTRY : AUTHENTICATED_ENTRY} />}
        />

        {isClient
          ? clientRoutes.map((route, index) => {
              return (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={
                    <AppRoute
                      routeKey={route.key}
                      component={route.component}
                      {...route.meta}
                    />
                  }
                />
              );
            })
          : protectedRoutes.map((route, index) => {
              return (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={
                    <AppRoute
                      routeKey={route.key}
                      component={route.component}
                      {...route.meta}
                    />
                  }
                />
              );
            })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
      {/* <Route path="/" element={<ShowroomRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{showroomRoutes.map((route, index) => {
					return (
						<Route
							key={route.key + index}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route> */}
    </RouterRoutes>
  );
};

export default Routes;
