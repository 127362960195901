import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store  , {persistor} from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { PersistGate } from 'redux-persist/integration/react';
import { useEffect } from 'react';
import {apiSlice} from 'store/rtk/apiSlice';
import { useDispatch } from 'react-redux';


const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV




function App() {


  return (
    <div className="App">
    
        <PersistGate loading={null} persistor={persistor} onError={(error) => {
              // Handle the error here
              console.error('Error during rehydration:', error);
            }}>

        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
        </PersistGate>
     
    </div>
  );
}

export default App;
