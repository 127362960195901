import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]
export const clientRoutes = [
    {
        key: 'customer-dashboard',
        path: `${APP_PREFIX_PATH}/customer/dashboard`,
        component: React.lazy(() => import('views/app-views/customer/index')),

    },
    {
        key: 'customer-profile',
        path: `${APP_PREFIX_PATH}/customer/profile`,
        component: React.lazy(() => import('views/app-views/customer/settings/index')),

    },
    {
        key: 'customer-profile-job',
        path: `${APP_PREFIX_PATH}/customer/job/:id`,
        component: React.lazy(() => import('views/app-views/customer/job-view/index')),

    }
]


export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.calendar',
        path: `${APP_PREFIX_PATH}/dashboards/calendar`,
        component: React.lazy(() => import('views/app-views/dashboards/Calendar')),
    },
    {
        key: 'dashboard.calendar.jobdetails',
        path: `${APP_PREFIX_PATH}/dashboards/calendar/jobdetail/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/CalendarJob')),
    },
    {
        key: 'dashboard.helpdesk.Tickets',
        path: `${APP_PREFIX_PATH}/helpdesk/tickets`,
        component: React.lazy(() => import('views/app-views/helpdesk/ticket')),
    },
    {
        key: 'dashboard.helpdesk.KnowledgeBase',
        path: `${APP_PREFIX_PATH}/helpdesk/knowledge-base`,
        component: React.lazy(() => import('views/app-views/helpdesk/knowlegeBase')),
    },
    {
        key: 'applications-appliance-addAppliance',
        path: `${APP_PREFIX_PATH}/appliance/add-appliance`,
        component: React.lazy(() => import('views/app-views/applications/appliance/add-appliance')),
    },
    {
        key: 'applications-appliance-list',
        path: `${APP_PREFIX_PATH}/appliance/list`,
        component: React.lazy(() => import('views/app-views/applications/appliance/appliance-list')),
    },
    {
        key: 'applications-appliance-viewAppliance',
        path: `${APP_PREFIX_PATH}/appliance/view-appliance/:id`,
        component: React.lazy(() => import('views/app-views/applications/appliance/view-appliance')),
    },
    {
        key: 'showroom-customer-list',
        path: `${APP_PREFIX_PATH}/showroom/customer/list`,
        component: React.lazy(() => import('views/app-views/showroom/customers/customer_list')),
    },
    {
        key: 'showroom-customer-add',
        path: `${APP_PREFIX_PATH}/showroom/customer/add`,
        component: React.lazy(() => import('views/app-views/showroom/customers/add-customer')),
    },
    {
        key: 'showroom-customer-view',
        path: `${APP_PREFIX_PATH}/showroom/customer/view/:id`,
        component: React.lazy(() => import('views/app-views/showroom/customers/view-customer')),
    },
    {
        key: 'showroom-customer-profile',
        path: `${APP_PREFIX_PATH}/showroom/customer/profile/:id`,
        component: React.lazy(() => import('views/app-views/showroom/customers/Profile/ProfileDashboard')),
    },
    {
        key: 'showroom-jobs-jobslist',
        path: `${APP_PREFIX_PATH}/showroom/jobs/list`,
        component: React.lazy(() => import('views/app-views/showroom/jobs/job-list')),
    },
    {
        key: 'showroom-jobs-view',
        path: `${APP_PREFIX_PATH}/showroom/jobs/view/:id`,
        component: React.lazy(() => import('views/app-views/showroom/jobs/job-view/JobShowView')),
    },
    {
        key: 'showroom-jobs-create-job',
        path: `${APP_PREFIX_PATH}/showroom/jobs/create-job`,
        component: React.lazy(() => import('views/app-views/showroom/jobs/create-job')),
    },
    {
        key: 'showroom-admin-users',
        path: `${APP_PREFIX_PATH}/showroom/admin/users`,
        component: React.lazy(() => import('views/app-views/showroom/admin/ShowroomUsers')),
    },
    {
        key: 'showroom-admin-adduser',
        path: `${APP_PREFIX_PATH}/showroom/admin/adduser`,
        component: React.lazy(() => import('views/app-views/showroom/admin/add-user')),
    },
    {
        key: 'showroom-admin-edituser',
        path: `${APP_PREFIX_PATH}/showroom/admin/edituser/:id`,
        component: React.lazy(() => import('views/app-views/showroom/admin/edit-user')),
    },
    {
        key: 'system-showroom-customer',
        path: `${APP_PREFIX_PATH}/systemshowroom/customer`,
        component: React.lazy(() => import('views/app-views/admin/customer/customer-view')),
    },
    {
        key: 'system-showroom-customer-add',
        path: `${APP_PREFIX_PATH}/systemshowroom/customer/add`,
        component: React.lazy(() => import('views/app-views/admin/customer/customer-add')),
    },
    {
        key: 'system-showroom-customer-edit',
        path: `${APP_PREFIX_PATH}/systemshowroom/customer/edit/:id`,
        component: React.lazy(() => import('views/app-views/admin/customer/customer-edit')),
    },
    {
        key: 'system-showroom-customer-profile',
        path: `${APP_PREFIX_PATH}/systemshowroom/customer/profile/:id`,
        component: React.lazy(() => import('views/app-views/admin/customer/customer-details')),
    },
    {
        key: 'system-showroom-customer-job',
        path: `${APP_PREFIX_PATH}/systemshowroom/customer/job/:id`,
        component: React.lazy(() => import('views/app-views/admin/customer/customer-job')),
    },

    {
        key: 'settings-profile',
        path: `${APP_PREFIX_PATH}/settings/profile/*`,
        component: React.lazy(() => import('views/app-views/settings')),
    },
    {
        key: 'admin-users',
        path: `${APP_PREFIX_PATH}/admin/users`,
        component: React.lazy(() => import('views/app-views/admin/users')),
    },
    {
        key: 'admin-users-create',
        path: `${APP_PREFIX_PATH}/admin/users/create`,
        component: React.lazy(() => import('views/app-views/admin/users/add-user')),
    },
    {
        key: 'admin-users-view',
        path: `${APP_PREFIX_PATH}/admin/users/view/:id`,
        component: React.lazy(() => import('views/app-views/admin/users/view-user')),
    },
    {
        key: 'admin-systemusers-create',
        path: `${APP_PREFIX_PATH}/admin/systemusers/create`,
        component: React.lazy(() => import('views/app-views/admin/users/add-systemuser')),
    },
    {
        key: 'admin-systemusers-view',
        path: `${APP_PREFIX_PATH}/admin/systemusers/view/:id`,
        component: React.lazy(() => import('views/app-views/admin/users/view-systemuser')),
    },
    {
        key: 'admin-showroom',
        path: `${APP_PREFIX_PATH}/admin/showroom`,
        component: React.lazy(() => import('views/app-views/admin/showroom')),
    },
    {
        key: 'admin-request',
        path: `${APP_PREFIX_PATH}/admin/request`,
        component: React.lazy(() => import('views/app-views/admin/appliance-request')),
    },
    {
        key: 'installer-jobs-list',
        path: `${APP_PREFIX_PATH}/installer/jobs/list`,
        component: React.lazy(() => import('views/app-views/installer/installerJob')),

    },
    {
        key: 'installer-jobs-view',
        path: `${APP_PREFIX_PATH}/installer/jobs/list/view/:id`,
        component: React.lazy(() => import('views/app-views/installer/JobView')),

    },

    {
        key: 'installer-mgnt',
        path: `${APP_PREFIX_PATH}/installer/management`,
        component: React.lazy(() => import('views/app-views/installer/JobMgnt')),

    },
    {
        key: 'installer-create',
        path: `${APP_PREFIX_PATH}/installer/create-job`,
        component: React.lazy(() => import('views/app-views/installer/JobCreate')),

    },
    {
        key: 'installer-work',
        path: `${APP_PREFIX_PATH}/installer/jobwork/:id`,
        component: React.lazy(() => import('views/app-views/installer/WorkView')),

    } ,
    {
        key: 'installer-pdf',
        path: `${APP_PREFIX_PATH}/installer/pdf/:id`,
        component: React.lazy(() => import('views/app-views/installer/PdfInstaller'))

    } ,


    {
        key: 'customer-dashboard',
        path: `${APP_PREFIX_PATH}/customer/dashboard`,
        component: React.lazy(() => import('views/app-views/customer/index')),

    }
]
