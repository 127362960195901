import { combineReducers } from 'redux'
import theme from '../store/slices/themeSlice'
import auth from '../store/slices/authSlice'
import api from '../store/rtk/apiSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        api,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
